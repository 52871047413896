<template>
  <MasterDetail
    formTitle="Cadastro de Consultor"
    :cols="cols"
    :opts="opts"
    :resourceUrl="resourceUrl"
    resourceListProperty="consultores"
    descriptionProperty="nome"
    :contextOptions="options"
  ></MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";

export default {
  name: "consultores",
  components: {
    MasterDetail,
  },
  computed: {
    resourceUrl: function () {
      return "/v1/consultores";
    },
  },
  data: function () {
    return {
      cols: [
        { key: "id", name: "ID", align: 1, hideInform: true },
        {
          key: "nome",
          name: "Nome",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
        },
        {
          key: "funcaoId",
          name: "Função",
          type: this.$fieldTypes.SELECT,
          rel: { to: "funcoes", key: "id", name: "funcao" },
          rules: [{ rule: "required" }],
        },
        {
          key: "quantidade_empresas",
          name: "Clientes",
          type: this.$fieldTypes.NUMBER,
          hideInform: true,
          editable: false,
        },
        {
          key: "email",
          name: "Email",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }, { rule: "email" }],
        },
        {
          key: "telefone",
          name: "Telefone",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
        },
        {
          key: "ultimo_acesso",
          name: "Último Acesso",
          type: this.$fieldTypes.FROMNOW,
          hideInform: true,
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ],
      rows: [],
      opts: {
        funcoes: { resourceProperty: "funcoes" },
      },
      options: [
        {
          name: "Ver clientes",
          limit: 1,
          icon: "",
          cb: (row) => {
            this.$router.push({ name: "listagem-empresas", query: { consultorId: row.id }});
          },
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
#head {
  #menu {
    ul {
      list-style: none;
      li {
        float: left;
        margin-right: 25px;
        a {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 19px;
          letter-spacing: 0.01em;
          color: #000000;
        }
      }
    }
  }

  #filter {
    .label {
      color: black;
      line-height: 30px;
      font-size: 14px;
      font-weight: bold;
    }

    .select {
      input {
        height: 30px;
      }
    }
  }
}
</style>